<template>
  <p class="d-flex align-items-center justify-content-between mb-0">
    <span class="footer-text mt-25">
      Skyworx.
    </span>

    <span class="footer-text mt-25">Collectium Version 3.1.9.1-0711ef0</span>

    <span class="footer-text">
      Powered By
      <img :src="Skyworx" alt="logo" class="ml-50" />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import Skyworx from '../../../assets/images/logo/skyworx.svg'

export default {
  components: {
    BLink
  },
  setup() {
    return {
      Skyworx
    }
  }
}
</script>
