import { ref, computed } from '@vue/composition-api'
import { isNavLinkActive, navLinkProps } from '@core/layouts/utils'
import store from '@/store'

export default function useVerticalNavMenuLink(item) {
  const isActive = ref(false)

  const linkProps = navLinkProps(item)

  const updateIsActive = () => {
    isActive.value = isNavLinkActive(item)
  }

  const isVerticalMenuCollapsed = computed({
    get: () => store.state.verticalMenu.isVerticalMenuCollapsed
  })
  return {
    isActive,
    linkProps,
    updateIsActive,
    isVerticalMenuCollapsed
  }
}
