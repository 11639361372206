<template>
  <b-navbar-nav class="nav">
    <b-nav-item class="py-50 pr-75 border-right" to="/">
      <img :src="GADAILogo" alt="Teppanku Logo" style="height:33px">
    </b-nav-item>

    <div class="ml-1 d-flex align-items-center">
      <h5 class="pl-25 mb-0">{{ userData.username }}</h5>
    </div>
  </b-navbar-nav>
</template>

<script>
import { BNavbarNav, BNavItem, BTooltip, BBadge } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import GADAILogo from '@/assets/images/logo/teppanku.png'

export default {
  components: {
    BNavbarNav, BNavItem, BTooltip, VuePerfectScrollbar, BBadge,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    return {
      perfectScrollbarSettings,
      GADAILogo,
    }
  },
  methods: {
    changeBranch(brch) {
      this.$http.post('/api/User/setactivebranch', {
        userId: this.userData.id,
        branchId: brch.id,
      }).then(res => {
        this.getProfile()
      }).catch(e => {
        console.log(e)
      })
    },
    getProfile() {
      this.$http.get('/api/user/my/profile')
        .then(response => {
          if (response == null) return

          const [data] = response?.data?.data

          localStorage.setItem('userData', JSON.stringify(data))
          this.$ability.update([
            {
              action: 'manage',
              subject: 'all',
            },
          ])

          window.location.reload()
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}
</style>
