<template>
  <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name mb-0">
          {{ userData.username || userData.name }}
        </p>
        <span class="user-status text-light">{{ userData.position }}</span>
      </div>
      <b-avatar size="40" :src="userData.avatar || defaultAvatar" variant="light-primary" badge class="badge-minimal"
        badge-variant="danger">
        <feather-icon v-if="!userData.name" icon="UserIcon" size="22" />
      </b-avatar>
    </template>

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import defaultAvatar from '../../../../../assets/images/avatars/default-avatar.svg'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      firstLoginTimestamp: null
    }
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'auth-login' })
    },
    setupAutoLogout() {
      this.firstLoginTimestamp = localStorage.getItem('firstLoginTimestamp');

      if (!this.firstLoginTimestamp) {
        localStorage.setItem('firstLoginTimestamp', new Date().getTime());
      }

      // Hitung selisih waktu sejak pertama kali login
      const dailyLogoutTimeout = 24 * 60 * 60 * 1000; // Waktu idle dalam milidetik (24 jam)
      let dailyLogoutTimeoutId;

      const resetDailyLogoutTimer = () => {
        clearTimeout(dailyLogoutTimeoutId);
        dailyLogoutTimeoutId = setTimeout(() => { this.logout(); }, dailyLogoutTimeout);
      };

      // Kondisi kedua: Logout otomatis setelah 5 menit tanpa aktivitas
      const idleTimeout = 60 * 60 * 1000; // Waktu idle dalam milidetik (5 menit)
      // const idleTimeout = 24 * 60 * 60 * 1000; // Waktu idle dalam milidetik (24 jam)
      let idleTimeoutId;

      const resetIdleTimer = () => {
        clearTimeout(idleTimeoutId);
        idleTimeoutId = setTimeout(() => { this.logout(); }, idleTimeout);
      };

      ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach((event) => {
        document.addEventListener(event, () => { resetDailyLogoutTimer(); resetIdleTimer(); });
      });

      resetDailyLogoutTimer();
      resetIdleTimer();
    },
  },
  setup() {
    return {
      defaultAvatar
    };
  },
  mounted() {
    this.setupAutoLogout();
  },
}
</script>

